.contact__wrap {
  margin: 10vh auto;
  width: 40%;
  background-color: #94ceca;
  border-radius: 30px;
  padding: 5px 20px;
}

.contact__wrap h2 {
  color: white;
  text-align: center;
  font-size: 2.5vh;
}

.contact__wrap .form__wrap {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  border-radius: 30px;
  background-color: white;
  padding: 2%;
}

.form__wrap input, textarea {
  font-family: 'Montserrat', sans-serif;
  padding: 0.5%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  border: none;
  border-bottom: #F0F0F0 2px solid;
}

.form__wrap textarea {
  resize: none;
 }

.form__wrap input:focus {
  outline: none;
  border-bottom: #14868C 2px solid;
}

.form__wrap textarea:focus {
  outline: none;
  border-bottom: #14868C 2px solid;
}

.contact__wrap button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  display: flex;
  margin: 2% auto;
  padding: 1.5% 4%;
  border-radius: 30px;
  border: none;
  background-color: #14868C;
  color: #FFFFFF;
  transition: all 0.2s linear;
}

.contact__wrap button:hover {
  cursor: pointer;
  scale: 1.05;
}


@media only screen and (max-width: 768px) {
  .contact__wrap {
    margin: 10vh auto;
    width: 90%;
  }
  
}