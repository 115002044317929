.tiles__wrap .tiles {
    width: 40%;
    background: #94CECA;
    border-radius: 30px;
    padding: 0 1.5% 1.5%;
    transition: all 0.1s linear;
    margin: 2%;
}

.tiles__wrap .tiles:hover {
    scale: 1.02; 
    cursor: pointer;
}

.tiles__wrap .tiles p {
    text-align: center;
    font-weight: 600;
    color: white;
    font-size: 2.2vh;
    padding: 2%;
    margin: 0;
}

.tiles .image__box {
    background-color: white;
    margin: auto;
    border-radius: 30px;
}

.tiles .image__box img {
    width: 100%;
    display: block;
    border-radius: 30px;
}

@media only screen and (max-width: 992px) {
    .tiles__wrap .tiles {
        width: 100%;
        margin-top: 5%;
    }

    .tiles__wrap .tiles:last-child {
        margin-bottom: 5%;
    }
  }