.about__wrap {
  display: flex;
  margin: auto;
  width: 60%;
  justify-content: space-between ;
}

.photo__wrap img {
  width: 250px;
  height: auto;
}

.text__wrap h2 {
  text-align: center;
  font-size: 2.5vh;
  color: white;
}

.about__wrap .text__wrap {
  background-color: #94ceca;
  border-radius: 30px;
  width: 60%;
}

.about__wrap .about__cont {
  width: 95%;
  padding: 1%;
  margin: auto;
  border-radius: 30px;
  margin-bottom:1.5%;
}

.about__cont p {
    margin: 0px !important;
    padding: 10px;
    color: white;
}

@media only screen and (max-width: 992px) {
  .about__wrap {
      width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .about__wrap {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
  }

  .about__wrap .text__wrap {
      width: 90%;
  }

  .about__wrap .photo__wrap {
    margin-bottom: 10vh;
  }
}