.tiles__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content:space-evenly;
    width: 80%;
    margin: auto;
}

@media only screen and (max-width: 992px) {
    .tiles__wrap {
        margin-top: 1vh;
        height: auto;
    }
}