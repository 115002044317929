.container .nav__mobile__hamburger {
    width: 24px;
}

.container .nav__mobile__logo {
    height: 30px;
    margin-top: 4px;
}

.container__side__nav {
    width: 70%;
    background-color: white;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    transition: all 0.5s;
    box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.active__blur {
    position: fixed;
    z-index: 1;
    top: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
    background-color: #00000040;
    transition: all 0.5s;
}

.container__side__nav.active {
    right: 0;
}

.nav__mobile__close {
    width: 24px;
    display: flex;
    margin-left: auto;
    padding: 4%;
}

.container__side__nav ul {
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    font-size: 1.3rem;
}


.container__side__nav ul li {
    margin-top: 10%;
    border-bottom: 1px solid #14868C;
    padding-bottom:2%;
    margin-bottom: 6%;
}