.nav {
    height: 50px;
    box-shadow: 0px 1px 6px -2px rgba(0, 0, 0, 0.1);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    background-color: #fff;
}

.nav .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}